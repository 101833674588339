import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';
import { IoLogoLinkedin } from "@react-icons/all-files/io5/IoLogoLinkedin";
import { IoLogoGithub } from "@react-icons/all-files/io5/IoLogoGithub";
import { IoLogoYoutube } from "@react-icons/all-files/io5/IoLogoYoutube";
import { HiOutlineMail } from "@react-icons/all-files/hi/HiOutlineMail";


export default ({ children }) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const contactIcons = [
    [<IoLogoLinkedin/>, "https://www.linkedin.com/in/yzhuk/", "LinkedIn"],
    [<IoLogoGithub/>, "https://github.com/Teetertater", "GitHub"],
    [<HiOutlineMail/>, "mailto:yuryivz@hotmail.com", "Email"],
    [<IoLogoYoutube/>, "https://www.youtube.com/c/YuryZhuk/featured", "YouTube"],
  ]

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          {url.split('/').pop() !== "" ? <Link to="/">{data.site.siteMetadata.title}</Link> : null}
         </div>
       
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        <div className="contact-icons">
          {contactIcons.map(([icon, href, text]) => (
            <a href={href} key={text}>
              {icon}
            </a>
          ))}
        </div>
        <p>&copy; {new Date().getFullYear()} Yury Zhuk </p>
      </footer>
    </div>
  )
}
